/* Cookie */
// const session = localStorage.getItem('banhjipossession') != null ? JSON.parse(localStorage.getItem('banhjipossession')) : {}
// const inst = session.ins || {}
const instituteId = localStorage.getItem('instituteId') != null ? localStorage.getItem('instituteId') : ''

export default class LoanType {
  constructor (data = {}) {
    this.uuid = data.uuid || '';
    this.name = data.name || '';
    this.code = data.code || '';
    this.description = data.description || '';
    this.institute_id = data.institute_id || instituteId;
  }
  constuct (data) {
    this.constructor(data);
  }
}